import React, { createContext, useContext } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

interface DropFormContext extends UseFormReturn<any> {
  isTitleVisible: boolean;
  toggleTitle: () => void;
  canShowTitle: boolean;
}

const FormContext = createContext<DropFormContext | null>(null);

/**
 * Extends the `react-hook-form` context with DropForm-specific state and logic
 * (e.g., title visibility management).
 */
export const useDropFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(
      'useDropFormContext must be used within a FormContextProvider'
    );
  }
  return context;
};

export const FormContextProvider: React.FC<{
  value: DropFormContext;
  children: React.ReactNode;
}> = ({ value, children }) => (
  <FormContext.Provider value={value}>
    <FormProvider {...value}>{children}</FormProvider>
  </FormContext.Provider>
);
