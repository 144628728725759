import { useMutation, useQueryClient } from 'react-query';
import type { Drop } from 'models/Drop';
import { saveDrop } from 'services/drop.service';
import { QueryKey } from 'services/queryClient';
import { notify, NotificationType } from 'services/NotificationService';
import { isContext } from 'utils/ContextUtils';
import { useXBox } from 'xstate/hooks/useXBox';

type Context = {
  previousDrops: Drop[];
};

export type Variables = {
  drop: Drop;
};

export const useSaveDrop = ({
  onSuccess,
}: {
  onSuccess?: (data: Drop) => void;
} = {}) => {
  const queryClient = useQueryClient();
  const selectedBox = useXBox().getSelectedBox();

  const onMutateCallback = async (
    variables: Variables
  ): Promise<Context | undefined> => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries([QueryKey.DROPS, selectedBox?.id]);

    // Snapshot the previous value
    const previousDrops = queryClient.getQueryData<Drop[]>([
      QueryKey.DROPS,
      selectedBox?.id,
    ]);

    if (previousDrops) {
      // Whether the drop already exists in the stack
      const itExists = previousDrops.some((d) => d.id === variables.drop.id);

      // Optimistically update to the new value
      if (itExists) {
        queryClient.setQueryData<Drop[]>(
          [QueryKey.DROPS, selectedBox?.id],
          previousDrops.map(
            (d) =>
              d.id !== variables.drop.id ? d : { ...d, ...variables.drop } // Apply partial updates
          )
        );
      } else {
        // We don't do anything because we don't have an ID to use as key
      }
    }

    return { previousDrops };
  };

  const onSuccessCallback = async (
    drop: Drop,
    _variables: Variables,
    context: Context | undefined
  ) => {
    if (context.previousDrops) {
      // Whether the drop already exists in the stack
      const itExists = context?.previousDrops.some((d) => d.id === drop.id);

      if (itExists) {
        // Update the recently updated drop
        queryClient.setQueryData<Drop[]>(
          [QueryKey.DROPS, selectedBox?.id],
          context.previousDrops.map((d) => (d.id !== drop.id ? d : drop))
        );

        // Notify User
        notify(NotificationType.DROP_UPDATED);
      } else {
        // Add the newly created drop
        queryClient.setQueryData(
          [QueryKey.DROPS, selectedBox?.id],
          (data: Drop[]) => {
            return [...data, drop];
          }
        );

        // Notify User
        notify(NotificationType.DROP_CREATED);
      }
    }

    if (onSuccess) {
      onSuccess(drop);
    }
  };

  const onErrorCallback = (
    _err: any,
    _variables: Variables,
    context: Context | undefined
  ) => {
    // If the mutation fails, use the context returned from onMutate to roll back
    if (isContext<Context>(context, 'previousDrops')) {
      queryClient.setQueryData<Drop[]>(
        [QueryKey.DROPS, selectedBox?.id],
        context.previousDrops
      );
    }
  };

  const saveDropMutation = useMutation<Drop, any, Variables, Context>(
    (variables) => saveDrop(variables.drop, selectedBox),
    {
      onMutate: onMutateCallback,
      onSuccess: onSuccessCallback,
      onError: onErrorCallback,
    }
  );

  return saveDropMutation;
};
